<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus">新增</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="80">
            </el-table-column>
            <el-table-column
                prop="bookId"
                label="书籍ID"
                width="80">
            </el-table-column>
            <el-table-column
                prop="title"
                label="章节标题">
            </el-table-column>
            <el-table-column
                prop="code"
                label="章节编码">
            </el-table-column>
            <el-table-column
                prop="chapterSort"
                label="章节顺序"
                width="80">
            </el-table-column>
            <el-table-column
                prop="createUserId"
                label="创建人"
                width="80">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="修改时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button @click="handleDetail(scope.row)" type="text">详情</el-button>
                    <el-button @click="handleAi(scope.row)" type="text">生成</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog title="章节" :visible.sync='open' width='80%' :before-close='cancel'>
      <br id="section1">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ detail.title }}</span>
        </div>
        <div>
          <div style="white-space: pre-wrap;">{{detail.content}}</div>
        </div>
      </el-card>
      <div slot='footer' class='dialog-footer'>
        <a  ><el-button type='primary' @click='handleNext'>下一章</el-button></a>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { bookAiChapterPage, bookAiEdit, queryChapterNext, chapterAiContent } from '@/service/book';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '书编码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '书名不能为空', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '来源不能为空', trigger: 'blur' }
        ]
      },
      userIds: [],
      detail: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      bookAiChapterPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {
          source: 2
        };
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          bookAiEdit(this.form).then(response => {
            if (response.code === 0) {
              this.cancel();
              this.getList();
            } else {
              this.$message({
                  type: 'info',
                  message: '异常'
              });  
            }
          });
        }
      });
    },
    tableDel(){
    },
    handleDetail(row){
      this.open = true;
      this.detail = row;
    },
    handleNext(){
      queryChapterNext({id:this.detail['id']}).then(response => {
        if (response.code === 0) {
          let data = response['data'];
          if(data === null){
            this.$message({
                type: 'info',
                message: '暂无下一章数据'
            });
            return;
          }
          this.detail = response['data'];
          document.getElementById('section1').scrollIntoView();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    handleAi(row){
      chapterAiContent({id:row.id}).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    }
  }
};
</script>